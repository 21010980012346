.top20-cards-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;

}

.top20-welcome {
   background: linear-gradient(0deg, rgba(100, 111, 122, 1) 9%, rgba(0, 0, 0, 0.700717787114846) 100%), url(https://i.imgur.com/D9i105n.png);
   background-size: cover;
   background-repeat: no-repeat;
   background-position: 50%;
   background-size: 120% 100%;
   width: 100%;
   height: 18rem;
   display: flex;
   flex-direction: column;
   align-items: center;

   padding-top: 3rem;
}


.top20-welcome>hr {
   width: 80%;
   color: whitesmoke;
}

.top20-welcome>h1 {
   font-size: 3rem;
   font-weight: bold;
   margin-bottom: 0;

   color: whitesmoke;
}

.top20-welcome>p {
   font-size: 1.4rem;
   color: whitesmoke;
   text-align: center;
   margin: 0 2rem;
}

.top20-selection-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 120px;
  
}
.top20-selection{
   background-color: #d9e0ed;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 15px;
   /*border style and shadow*/
   border-radius: 15px;
   border-radius: 10px;
   box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
   transition: all 0.2s;
}
.top20-selection > h2{
   font-size: 1.8rem;

   font-weight: bold;
   margin-bottom: 10px;
}

.top20-selection > select{

   font-size: 1.8rem;
   border: none;
   border-radius: 5px;
}




@media (min-width: 572px) {
   .top20-cards-wrapper {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
   }

   .top20-welcome {
      height: 30rem;
      padding-top: 10rem;
      
   background-size: 120% 120%;
   }

   .top20-welcome>h1 {
      font-size: 4rem;
   }
   .top20-welcome>p {
      font-size: 2rem;
   }

}