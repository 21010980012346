.home-social {
    background-color: rgb(237, 237, 237);
}

.home-logo-container {

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 8rem;
    height: 5rem;

}

.home-logo-item {
    color: inherit;
    text-decoration: inherit;
}

.home-logo-item>i {
    font-size: 2.4rem;
}

.home-content-welcome {
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(
        90deg, rgba(255, 255, 255, 0.781) 30%, rgba(255,255,255,0.5102415966386555) 100%
    ),url('../../img/ph.jpg');
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    
}
.home-content-welcome > h1{
    margin-left: 3rem;
    font-weight: bold;
    font-size: 3rem;
}
.home-content-welcome > p{
    margin-left: 3rem;
    font-size: 1.2rem;
    width: 50%;
}
.home-content-right {
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    
    background: linear-gradient(
        90deg, rgba(255,255,255,0.5298494397759104) 0%, rgba(255,255,255,1) 70%
    ),url('../../img/phr.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    
}
.home-content-right > h1{
    margin-right: 3rem;
    font-weight: bold;
    font-size: 3rem;
}
.home-content-right > p{
    margin-right: 3rem;
    font-size: 1.2rem;
    text-align: end;
    width: 50%;
}

.home-content-about-us{
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40rem;
    padding-top: 3rem;

    background: linear-gradient(
        0deg, rgba(34,193,195,0.3841911764705882) 0%, rgba(38,2,156,1) 63%
    ),url('https://i.imgur.com/37skcEQ.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
}

.home-content-about-us >hr{
    width: 80%;
    color: whitesmoke;
}

.home-content-about-us > h1{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0;

    color: whitesmoke;
}
.home-content-about-us > p{
    font-size: 1.4rem;
    color: whitesmoke;
    text-align: center;
    margin: 0 2rem;
}
.home-location{
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding-top: 3rem;

    height: 34rem;

    background: linear-gradient(
        0deg, rgba(73,73,73,0.8) 0%, rgba(73,73,73,0.8) 100%
    ),url('https://i.imgur.com/DBbHwfF.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
}

.home-location >hr{
    width: 80%;
    color: whitesmoke;
}

.home-location > h1{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0;

    color: whitesmoke;
}
.home-location > p{
    font-size: 1.4rem;
    color: whitesmoke;
    text-align: center;
    margin: 0 2rem 1.8rem;
}
#gmap_canvas{
    width: 80%;
    border-radius: 10px;
}
@media  (max-width: 572px) {
    .home-content-welcome{
        height: 300px;
        padding-top: 8px;
        background-position: center left;
    }
    .home-content-welcome > h1{
        font-size: 2rem;
    }
}
@media  (min-width: 572px)  {
    .home-content-welcome{
        height: 500px;
    }
    .home-content-about-us{
        height:500px;
    }
    .home-content-about-us >h1{
        margin-top: 3rem;
    }

    .home-content-about-us > p{
        
        margin: 0 20rem;
        
    }
    .home-location{
        height: 400px;
    }
    .home-location > h1{
        margin-top: 4rem;
    }
  }