.tourney-cards-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.tourney-content-welcome{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding-top: 4rem;

    height: 20rem;

    background: linear-gradient(
        0deg, rgba(100,111,122,1) 9%, rgba(0,0,0,0.700717787114846) 100%
    ),url('../../img/tor_img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.tourney-content-welcome >hr{
    width: 80%;
    color: whitesmoke;
}

.tourney-content-welcome > h1{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0;

    color: whitesmoke;
}
.tourney-content-welcome > p{
    font-size: 1.4rem;
    color: whitesmoke;
    text-align: center;
    margin: 0 2rem ;
}