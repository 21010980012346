.top-card-container {
    width: 30rem;
    height: 41.5rem;
    color: inherit;
    text-decoration: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 2rem;
    padding-top: 2rem;

    /*background img*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    /*border style and shadow*/
    border-radius: 15px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
}

.top-card-container:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.03);
}

.top-card-container>figure {
    width: 26rem;
    height: 26rem;
    margin: 0;
    margin-bottom: 1rem;
}

.top-card-container>figure>img {
    max-width: 100%;
    max-height: 100%;

    border-radius: 15px;
}

.top-card-container>hr {
    width: 26rem;
    color: rgb(255, 255, 255);
    margin: 0.2rem 0;
    padding: 0 2rem;
}

.top-card-container>h1 {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 900;
    font-size: 3rem;
    margin: 0;
    color: white;
}


.top-card-info-container {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.4rem;
}

.top-card-info-container p {

    font-weight: 600;
    color: white;
}

.top-card-info-container p>span {
    font-weight: 400;
}
.top-card-container .top-card-title-special{
    font-size: 2.6rem ;
 }

@media (min-width: 580px) {
    .top-card-container {
        width: 25rem;
        height: 35rem;
    }

    .top-card-container>figure {
        width: 21rem;
        height: 21rem;
    }

    .top-card-container>hr {
        width: 21rem;
    }
    .top-card-container>h1 {
       
        font-size: 2.6rem;
    }
    
   
    .top-card-info-container p {
        font-size: 1.2rem;
    }
    .top-card-container .top-card-title-special{
        font-size: 2.2rem;
     }
    
}