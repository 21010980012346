.tourney-card{
    width:90%;
    height:13rem;
    display: flex;
    background-color: white ;
    
    margin-bottom: 15px;
    align-items: center;

    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(101, 101, 101);
    transition: box-shadow .3s;
}

.tourney-card:hover {
    box-shadow: 0 0 11px rgba(1,1,1,.2); 
}



.tourney-card-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin:  15px;
}

.tourney-card-img img{
    width: 100%;
    border-radius: 5px;
}
.tourney-card-data{
    color: inherit; 
    text-decoration: inherit;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.tourney-card-data >h1{
    font-weight: 800;
    margin: 0;
}
.tourney-card-data >span{
    font-size: 1.2rem;
    
}

.tourney-card-more{
    width: 3rem;
}
.more-arrow{

    width:15px;
    color: rgb(101, 101, 101); 
    text-decoration: inherit;
    font-size: 1.4rem;
    margin-right: 0.4rem;
}