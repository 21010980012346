
html {
    font-size: 62.5% !important;
    
    background-color: #646f7a;
}

body {
    font-family: 'Montserrat', sans-serif !important; 
}
#root{
    background-color: #646f7a;
}

